@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  touch-action: manipulation;
}

body {
  margin: 0;
  font-family: 'poppin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'poppin';
  src: url('../public/Fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'inter';
  src: url('../public/Fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

.inter{
  font-family: 'inter';
}

*::-webkit-scrollbar{
  display: none;
}

.animatefromtop{
  animation-name: animatefromtop;
  animation-duration: 300ms;
}

@keyframes animatefromtop {
  from{
    transform: translateY(-100%);
  }to{
    transform: translateY(0%);
  }
}
.animatefromright{
  animation-name: animatefromright;
  animation-duration: 200ms;
}

@keyframes animatefromright {
  from{
    transform: translateX(100%);
  }
}
.animatefromleft{
  animation-name: animatefromleft;
  animation-duration: 200ms;
}

@keyframes animatefromleft {
  from{
    transform: translateX(-100%);
  }
}

.transformRight{
  transform: translateX(-100%);
}

.animatefrombottom {
  animation-name: animatefrombottom;
  animation-duration: 200ms;
}

@keyframes animatefrombottom {
  from {
    transform: translateY(100%);
  }
}

.animateshow1 {
  animation-name: animateshow1;
  animation-duration: 1s;
}

@keyframes animateshow1 {
  from {
    opacity: 0;
  }
}
.animateshow2 {
  animation-name: animateshow2;
  animation-duration: 2s;
}

@keyframes animateshow2 {
  from {
    opacity: 0;
  }
}
.animateshow3 {
  animation-name: animateshow3;
  animation-duration: 3s;
}

@keyframes animateshow3 {
  from {
    opacity: 0;
  }
}
.animateshow4 {
  animation-name: animateshow4;
  animation-duration: 4s;
}

@keyframes animateshow4 {
  from {
    opacity: 0;
  }
}
.animateshow5 {
  animation-name: animateshow5;
  animation-duration: 5s;
}

@keyframes animateshow5 {
  from {
    opacity: 0;
  }
}
.animateshow6 {
  animation-name: animateshow6;
  animation-duration: 6s;
}

@keyframes animateshow6 {
  from {
    opacity: 0;
  }
}
.animateshow7 {
  animation-name: animateshow7;
  animation-duration: 7s;
}

@keyframes animateshow7 {
  from {
    opacity: 0;
  }
}


.animatewidth {
  animation-name: animatewidth;
  animation-duration: 1s;
}

@keyframes animatewidth {
  from {
    width: 0;
  }
}

.lineLoading {
  animation-name: lineLoading;
  animation-duration: 1.5s;
  animation-delay: 0;
  animation-iteration-count: infinite;
}

@keyframes lineLoading {
  from {
    left: 0%;
  }

  to {
    left: 100%
  }
}